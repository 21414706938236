import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'antd/dist/antd.css';
import App from './screens/App/App';
import Dashboard from './screens/Dashboard';
import Nfs from './screens/Nfs';
import ConsultaMovimento from './screens/ConsultaMovimento';
import Reprocessar from './screens/Reprocessar';
import Cadastrar from './screens/Cadastrar';
import Sucesso from './screens/Sucesso';

import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router, Route} from 'react-router-dom';
ReactDOM.render(
  <Router>
    <Route exact path="/" component = {App}/>
    <Route exact path="/dashboard" component = {Dashboard}/>
    <Route exact path="/nfs" component = {Nfs}/>
    <Route exact path="/consultamovimento" component = {ConsultaMovimento}/>
    <Route exact path="/cadastrar" component = {Cadastrar}/>
    <Route exact path="/reprocessar" component = {Reprocessar}/>
    <Route exact path="/sucesso" component = {Sucesso}/>

  </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
