import React from 'react';
import {Row, Col,  Button,Result } from 'antd';
import LayoutDetail from '../Layout/'


function Sucesso() {
  
  return (
   <LayoutDetail>
      <Result
    status="success"
    title="Sua nota foi cadastrada com sucesso"
    subTitle="Sua nota foi manualmente cadastrada, por favor confirme os dados foram enviados corretamente"
    extra={[
      <Button type="primary" key="console">
       Ir para o Dashboard
      </Button>,
      <Button key="buy">Adicionar nova nota</Button>,
    ]}
  />
   </LayoutDetail>

  );
}

export default Sucesso;
