import React from 'react';
import { Layout, Row, Col,  Form, Input, Button, Checkbox } from 'antd';
import { useHistory } from "react-router-dom";

const { Header, Footer, Content } = Layout;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};
function App() {
  let history = useHistory();

    const onFinish = values => {
      console.log('Success:', values);
      if(values.username === 'evidence' && values.password === 'evidence@1905!'){
        history.push("/dashboard");
      }
      else {
        alert('Tente novamente')
      }
    };
  
    const onFinishFailed = errorInfo => {
      console.log('Failed:', errorInfo);
    };
  return (
    <Layout>
    <Header></Header>
    <Content>
      <Row style={{marginTop:50}}>
      <Col span={8}></Col>
        <Col>
        <h3>Controle Fiscal</h3>
        </Col>
        <Col span={8}></Col>
      </Row>
      <Row>
        <Col span={8}></Col>
        <Col span={8}style={{backgroundColor: '#eee', padding: 20}}>  
        <Form
      {...layout}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Usuário:"
        name="username"
        rules={[{ required: true, message: 'Por favor informe o usuario!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Senha"
        name="password"
        rules={[{ required: true, message: 'Por favor informe a senha!' }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item {...tailLayout} name="remember" valuePropName="checked">
        <Checkbox>Lembrar</Checkbox>
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Enviar
        </Button>
      </Form.Item>
    </Form>
        </Col>
       
      </Row>
    </Content>
    <Footer>
          <p>TIC Evidence - 3.0</p>
    </Footer>
  </Layout>

  );
}

export default App;
