import React, { useState } from 'react';
import { Row, Col,  Button, Table,Skeleton, DatePicker} from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { CaretRightOutlined } from "@ant-design/icons";
import moment from 'moment';
import LayoutDetail from '../Layout/'
import api from '../../api';
const columns = [
    {
        dataIndex: 'sucesso',
        width: 5,
        render: text => <CaretRightOutlined style={{color: text === true? 'green' : 'red'}} />
      },
    {
      title: 'Cliente',
      dataIndex: 'cliente',
      width: 250,
    },
    {
      title: 'Cupom',
      dataIndex: 'numeroCupomSat',
      width: 150,
    },
    {
      title: 'Nota',
      dataIndex: 'numeroNota',
      width: 150,
    },
    {
        title: 'XML',
        dataIndex: 'urlXmlNota',
        width: 150,
        render: text =>  
        <Button type="primary" href={text}
        shape="round" icon={<DownloadOutlined />}>
        Download
      </Button>
      },
      {
        title: 'PDF',
        dataIndex: 'urlPdfNota',
        width: 150,
        render: text =>  
        <Button type="primary" href={text}
        shape="round" icon={<DownloadOutlined />} >
        Download
      </Button>

      },
  ];
function Nfs() {
    const [data, setData] = useState([]);
    const [dataFixa, setDataFixa] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedDate, setNewDate] = useState(moment(Date.now()).format('YYYY-MM-DD'));

    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
    async function handleDatePickerChange(date, dateString) {
        console.log('[DATA ANTIGA]:', selectedDate)

        if(date != undefined){
            console.log('data: ', date.format('YYYY-MM-DD'));
            await setNewDate(date.format('YYYY-MM-DD'))
        } else {
            await setNewDate(undefined);
        }
        setLoading(true);
        console.log('URL:', '/Movimento/ConsultarEmissaoNfse/' + date.format('YYYY-MM-DD'))
        api.get('/Movimento/ConsultarEmissaoNfse/' + date.format('YYYY-MM-DD'),  { headers: {'Content-Type': 'application/json'}
        }).then(function (response) {
            console.log('/Movimento/ConsultarEmissaoNfse route: [SUCESSO]:', response);
            setLoading(false);
            setData(response.data);
            setDataFixa(response.data);
        }).catch(function (error) {
            console.log('/Movimento/ConsultarEmissaoNfse route: [ERROR]:', error);
            setLoading(false);
            setData([])
        });
    }
    function filtraNotas(codigoFilial) {
        console.log(['Recuperando dados da filial:', codigoFilial]);
        console.log(['DataFixa:', dataFixa]);
        setLoading(true);
        var newData = [];
        dataFixa.map(x => {
            console.log('Filial é:', x.filial)
            if(x.filial == codigoFilial){
                newData.push(x);
            }
        })
        console.log(['filtrada:', newData]);
        setData(newData);
        setLoading(false);
    }
    
  return (
   <LayoutDetail breadCrumbTitle='NFS'>
       <Row>
           <Col>
           <h2>Consulta notas de serviço</h2>
           </Col>
       </Row>
       <Row>
           <Col span={12}>Escolha uma das filiais para consultar</Col>  
       </Row>
      
       <Row>
           <Col span={4} style={{paddingTop:5}}>
                <DatePicker onChange={(date, dateString) => handleDatePickerChange(date, dateString)} defaultValue={moment()} format={dateFormatList} />
            </Col>
           <Col>
            <Button onClick={() => filtraNotas('001 - MATRIZ')} type="primary" style={{margin:5}} >
                01 - MATRIZ
            </Button>
            
           </Col>        
           <Col>
            <Button onClick={() => filtraNotas('004 - LOJA HARMONY')}  type="primary"  style={{margin:5}} >
                04 - HARMONY
            </Button>
            
           </Col>        
           <Col>
            <Button onClick={() => filtraNotas('030 - CENTRAL ATENDIMENTO')}  type="primary"  style={{margin:5}} >
                30 - CENTRAL
            </Button>
            
           </Col>        
           <Col>
            <Button onClick={() => filtraNotas('033 - FILIAL RIOMAR FORTAL')}  type="primary" style={{margin:5}}  >
                33 - RIO MAR
            </Button>
            
           </Col>        
       </Row>
       <Row>
           <h3>Documentos fiscais:</h3>
       </Row>
       <Row style={{backgroundColor:'#eee', padding:10}}>
       <Col span={24}>
               {
                   loading == false ?(
                    <Table columns={columns} dataSource={data} scroll={{ x: 1100 }} pagination={{ pageSize: 10 }} />
                   ):(
                    <Skeleton loading />
                   )
               }
           </Col>
       </Row>
   </LayoutDetail>

  );
}

export default Nfs;
