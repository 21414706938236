import React, { useState } from 'react';

import { Row, Col,  Button, Skeleton, Table, Tag, DatePicker} from 'antd';
import moment from 'moment';
import LayoutDetail from '../Layout/'
import api from '../../api';
const columns = [
    {
      fixed:'left',
      title: 'Cliente',
      dataIndex: 'cliente',
      key:'cliente',
      width:250
    },
    {
      title: 'Cupom',
      dataIndex: 'numeroCupomSat',
      width: 100,
      key:'numeroCupomSat'
    },
    {
      title: 'CPF',
      dataIndex: 'cpfCliente',
      key:'cpfCliente',
      width:130,
    },
    {
        title: 'Filial',
        dataIndex: 'filial',
        key:'filial'
      },
    {
        title: 'Endereço',
        dataIndex: 'enderecoCliente',
        key:'enderecoCliente',
        width:450
      },
      {
        title: 'UF',
        dataIndex: 'estadoCliente',
        key:'estadoCliente',
        width:50
      },
      {
        title: 'CEP',
        dataIndex: 'cpfCliente',
      },
      {
        title: 'Complemento',
        dataIndex: 'complementoLogradouroCliente',
      },
      {
       
        width:100,
        key:'valorTotal',
        title: 'Valor Total',
        dataIndex: 'valorTotal',
        render: text =>  <Tag color="#87d068">{text.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</Tag>,
      },
      {
        title: 'Valor Formula',
        dataIndex: 'valorFormula',
        render: text =>  <Tag color="#87d068">{text.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</Tag>,
      },
      {
        title: 'Valor Revenda',
        dataIndex: 'valorRevenda',
        render: text =>  <Tag color="#87d068">{text.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</Tag>,
      },
  ];
function ConsultaMovimento() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedDate, setNewDate] =  useState(moment(Date.now()).format('YYYY-MM-DD'));

    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YYYY'];
    function handleDatePickerChange(date, dateString) {
        if(date != undefined){
            console.log('data: ', date.format('YYYY-MM-DD'));
            //setNewDate(date.year() + '-' + (date.month() + 1)  + '-' + date.date());
            setNewDate(date.format('YYYY-MM-DD'))
        } else {
            setNewDate(undefined);
        }
   }
    function recuperarNotasFilial(codigoFilial){
        setLoading(true);
        console.log(['Recuperando dados da filial:', codigoFilial]);

        var filtro = {}
        if(codigoFilial != 0){
            filtro = { dataMovimento:selectedDate,  filial: parseInt(codigoFilial) }
        } else {
            filtro = { dataMovimento:selectedDate }
        }
        
        console.log('[FILTRO]', filtro)
        api.post('/Movimento', filtro,  {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(function (response) {
            console.log('/Movimento route: [SUCESSO]:', response);
            setLoading(false);

            setData(response.data);
          })
          .catch(function (error) {
            setLoading(false);
            setData([]);
            console.log('/Movimento route: [ERROR]:', error);
          }); 
    }
    
  return (
   <LayoutDetail breadCrumbTitle='NFS'>
       <Row>
           <Col>
           <h2>Consulta notas de serviço</h2>
           </Col>
       </Row>
       <Row>
           <Col span={12}>Escolha uma das filiais para consultar</Col>  
       </Row>
      
       <Row>
           <Col span={4} style={{paddingTop:5}}>
                <DatePicker onChange={(date, dateString) => handleDatePickerChange(date, dateString)} defaultValue={moment()} format={dateFormatList} />
            </Col>
            <Col>
            <Button onClick={() => recuperarNotasFilial(0)} type="primary" style={{margin:5}} >
                TODOS            
            </Button>
           </Col>   
           <Col>
            <Button onClick={() => recuperarNotasFilial(1)} type="primary" style={{margin:5}} >
                01 - MATRIZ
            </Button>
           </Col>        
           <Col>
            <Button onClick={() => recuperarNotasFilial(4)} type="primary"  style={{margin:5}} >
                04 - HARMONY
            </Button>
            
           </Col>        
           <Col>
            <Button  onClick={() => recuperarNotasFilial(30)} type="primary"  style={{margin:5}} >
                30 - CENTRAL
            </Button>
            
           </Col>        
           <Col>
            <Button onClick={() => recuperarNotasFilial(33)} type="primary" style={{margin:5}}  >
                33 - RIO MAR
            </Button>
            
           </Col>        
       </Row>
       <Row>
           <h3>Documentos fiscais:</h3>
       </Row>
       <Row style={{backgroundColor:'#eee', padding:10}}>
           <Col span={24}>
               {
                   loading == false ?(
                    <Table columns={columns} dataSource={data} scroll={{ x: 2100 }} pagination={{ pageSize: 10 }} />
                   ):(
                    <Skeleton loading />
                   )
               }
           </Col>
       </Row>
   </LayoutDetail>

  );
}

export default ConsultaMovimento;
