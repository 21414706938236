import React from 'react';
import { Layout, Menu, Breadcrumb, Row } from 'antd';
import { UserOutlined, LaptopOutlined } from '@ant-design/icons';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;

const LayoutDetail = ({children, breadCrumbTitle}) => {
  return (
    
    <Layout>
    <Header className="header">
      <h3 style={{color:'#fff'}}>Evidence - Controle Fiscal</h3>
    </Header>
    <Row style={{backgroundColor:'#1e4661', padding:10, paddingLeft:50}}>
        <Breadcrumb style={{ margin: '5px 0', color:'#fff' }}>
            <Breadcrumb.Item style={{color:'#eee'}}>Home</Breadcrumb.Item>
            <Breadcrumb.Item style={{color:'#eee'}}>{breadCrumbTitle}</Breadcrumb.Item>
        </Breadcrumb>
        </Row>
    <Content style={{ padding: '0 50px' }}>
        
    
      <Layout className="site-layout-background" style={{ padding: '24px 0' }}>
        <Sider className="site-layout-background" width={200}>
          <Menu
            mode="inline"
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            style={{ height: '100%' }}
          >
            <SubMenu key="sub1" icon={<UserOutlined />} title="Relatórios">
              <Menu.Item  key="1">
                 <Link to="/nfs/">Consulta NFS</Link>
              </Menu.Item>
              <Menu.Item  key="2">
                 <Link to="/consultamovimento/">Consulta Movimentos</Link>
              </Menu.Item>        
              </SubMenu>
            <SubMenu key="sub2" icon={<LaptopOutlined />} title="Ações">
              <Menu.Item key="5">
                 <Link to="/reprocessar/">Reprocessar Notas</Link>
              </Menu.Item>
              <Menu.Item key="6">  <Link to="/cadastrar/">Cadastrar Notas</Link>
              </Menu.Item>
            </SubMenu>
          </Menu>
        </Sider>
        <Content style={{ padding: '0 24px', minHeight: 280 }}>{children}</Content>
      </Layout>
    </Content>
    <Footer style={{ textAlign: 'center' }}>TIC Evidence - v2.0</Footer>
  </Layout>
    
  );
}

export default LayoutDetail;
