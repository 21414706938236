import React from 'react';
import {Row, Col,  Button } from 'antd';
import LayoutDetail from '../Layout/'


function Dashboard() {
  
  return (
   <LayoutDetail>
       <Row>
           <Col>
           <h2>Ola Evidence</h2>
           </Col>
       </Row>
       <Row>
           <Col span={5}>Você tem <b>ZERO</b> notas para reprocessar identificadas</Col>  
           <Col>
           <Button type="primary" disabled>
             Central de reprocessamento
           </Button>
           </Col>        
       </Row>

   </LayoutDetail>

  );
}

export default Dashboard;
