import React, { useState } from 'react';
import { useHistory } from "react-router-dom";

import { Row, Col, Button, Input, DatePicker } from 'antd';
import LayoutDetail from '../Layout/'
import moment from 'moment';
import api from '../../api';


function Cadastrar() {
    let history = useHistory();

    const [dataMovimento, setDataMovimento,] = useState(moment(Date.now()).format('YYYY-MM-DD'));
    const [filial, setFilial] = useState(0);
    const [numeroCupom, setNumeroCupom] = useState(0);
    const [numeroNota, setNumeroNota] = useState(0);
    const [valor, setValor] = useState(0);
    const [loading, setLoading] = useState(false);


    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
    async function handleDatePickerChange(date, dateString) {
        await setDataMovimento(date.format('YYYY-MM-DD'))
    }
    async function cadastrarNota() {
        setLoading(true);
        console.log('Cadastra nota');
        const dataToSend = {
            dataMovimento:dataMovimento,
            filial:parseInt(filial), 
            numeroCupom:parseInt(numeroCupom), 
            numeroNota:parseInt(numeroNota), 
            valor:parseFloat(valor)
        }
        console.log('Cadastra nota', dataToSend);
        api.post('/Movimento/CadastrarNotaNfseManual', dataToSend)
            .then(function (response) {
                console.log(response);
                setLoading(false);
                history.push("/sucesso");
            }).catch(error => {
                try {
                    setLoading(false);
                    console.log(error)
                } catch (e) {
                    setLoading(false);
                    console.log(e)
                }

            });
    }
    return (
        <LayoutDetail>
            <Row style={{ marginBottom: 10 }}>
                <Col>
                    <h2>Cadastrar notas manualmente</h2>
                </Col>
            </Row>
            <Row style={{ marginBottom: 10 }}>
                <Col>
                    <DatePicker onChange={(date, dateString) => handleDatePickerChange(date, dateString)} defaultValue={moment()} format={dateFormatList} />
                </Col>
            </Row>
            <Row style={{ marginBottom: 10 }}>
                <Col>
                    <Input onChange={e => setFilial(e.target.value)} placeholder="Filial"></Input>
                </Col>
            </Row>
            <Row style={{ marginBottom: 10 }}>
                <Col>
                    <Input onChange={e => setNumeroCupom(e.target.value)} placeholder="Número Cupom"></Input>
                </Col>
            </Row>
            <Row style={{ marginBottom: 10 }}>
                <Col>
                    <Input onChange={e => setNumeroNota(e.target.value)} placeholder="Número Nota"></Input>
                </Col>
            </Row>
            <Row style={{ marginBottom: 10 }}>
                <Col>
                    <Input onChange={e => setValor(e.target.value)} placeholder="Valor"></Input>
                </Col>
            </Row>

            <Row style={{ marginBottom: 10 }}>
                <Col>
                    {
                        loading == false ? (
                                <Button onClick={() => cadastrarNota()} type="primary" >
                                    Cadastrar
                                </Button>
                        ) : (
                                <Button type="primary" loading>
                                    Cadastrando...
                                </Button>
                            )
                    }

                </Col>
            </Row>

        </LayoutDetail>

    );
}

export default Cadastrar;
