import React, { useState } from 'react';
import { Row, Col, Button, Table, Input, Skeleton, DatePicker, } from 'antd';
import LayoutDetail from '../Layout/';
import moment from 'moment';

const columns = [
    {
        title: 'Cliente',
        dataIndex: 'cliente',
        width: 250,
    },
    {
        title: 'Filial',
        dataIndex: 'filial',
        width: 50,
    },
    {
        title: 'Cupom',
        dataIndex: 'numeroCupomSat',
        width: 150,
    },
    {
        title: 'Reprocessar',
        dataIndex: 'numeroCupomSat',
        render: text => <Button href={'api/' + text} type="primary">Reprocessar</Button>,
        width: 50,
    },
];
function Reprocessar() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedDate, setNewDate] = useState(moment(Date.now()).format('YYYY-MM-DD'));
    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

    function filtraNotas() {
        console.log('filtrando notas...')
    }
    function handleDatePickerChange() {
        console.log('handleDatePickerChange ...')
    }
    return (
        <LayoutDetail>
            <Row>
                <Col span={4} style={{ paddingTop: 5 }}>
                    <Input placeholder="CUPOM" />
                </Col>
                <Col span={4} style={{ paddingTop: 5 }}>
                    <DatePicker onChange={(date, dateString) => handleDatePickerChange(date, dateString)} defaultValue={moment()} format={dateFormatList} />
                </Col>
                <Col>
                    <Button onClick={() => filtraNotas(1)} type="primary" style={{ margin: 5 }} >
                        01 - MATRIZ
            </Button>

                </Col>
                <Col>
                    <Button onClick={() => filtraNotas(4)} type="primary" style={{ margin: 5 }} >
                        04 - HARMONY
            </Button>

                </Col>
                <Col>
                    <Button onClick={() => filtraNotas(30)} type="primary" style={{ margin: 5 }} >
                        30 - CENTRAL
            </Button>

                </Col>
                <Col>
                    <Button onClick={() => filtraNotas(33)} type="primary" style={{ margin: 5 }}  >
                        33 - RIO MAR
            </Button>

                </Col>
            </Row>
            <Row>
                <Col>
                    <h2>Detectamos as seguintes notas com problemas</h2>
                </Col>
            </Row>


            <Row style={{ backgroundColor: '#eee', padding: 10 }}>
                <Col span={24}>
                    {
                        loading === false ? (
                            <Table columns={columns} dataSource={data} scroll={{ x: 600 }} pagination={{ pageSize: 10 }} />
                        ) : (
                                <Skeleton loading />
                            )
                    }
                </Col>
            </Row>

            <Row>
                <Col>
                    <Button type="primary">
                        Reprocessar todas as notas da lista
            </Button>
                </Col>
            </Row>
        </LayoutDetail>

    );
}

export default Reprocessar;
